import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Dropdown} from "react-bootstrap";

const CorpusList = observer(() => {
    const {map} = useContext(Context)


    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">Выберите корпус</Dropdown.Toggle>
            <Dropdown.Menu>
            {map.corpuses.map(corpus =>
                <Dropdown.Item
                    as="button"
                    style={{cursor: 'pointer'}}
                    active={corpus.id === map.selectedCorpus.id}
                    onClick={() => map.setSelectedCorpus(corpus)}
                    key={corpus.id}
                >
                    {corpus.number === 8 ? '6A' : corpus.number}
                </Dropdown.Item>
            )}
            </Dropdown.Menu>
        </Dropdown>
    );
});

export default CorpusList;